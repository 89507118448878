import React, { useEffect, useMemo, useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import bullionStats from "../assets/bullioStats.png";
import bgimg from "../assets/bgimg.png";
import USDtoINR from "../assets/USDtoINR.png";
import USDtoSGD from "../assets/USDtoSGD.png";
import USDtoEUR from "../assets/USDtoEUR.png";
import sajLogo from "../assets/sajLogo.png";
import logo from "../assets/logo.png";
import { Box, Fade, LinearProgress, Modal, Typography } from "@mui/material";
import Marquee from "react-fast-marquee";
import "../App.css";
import moment from "moment-timezone";
import io from "socket.io-client";
import {
  allCommodities,
  allNewses,
  findBanners,
  findUserIp,
  getCurrencyValueWithAskBid,
  getLiveValueTypeForDisplay,
  getRatio,
  getSocketUrl,
  getSpread,
} from "../sevice/home";
import { loginChecking, logoutTv, tvLoging } from "../sevice/login";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LimitExceededModal from "./LimitExceededModal";
import SubscriptionExpiringSoon from "./SubscriptionExpiringSoon";
import SubscriptionExpired from "./SubscriptionExpired";
import Carousel from "react-material-ui-carousel";
import { firebaseAuth } from "../sevice/config";
import { deviceDetect } from "react-device-detect";
import BlockModal from "./BlockModal";
const { makeStyles } = require("@mui/styles");
let socket = Cookies.get("socketUrl")
  ? io(JSON.parse(Cookies.get("socketUrl")))
  : null;

const socket2 = io(process.env.REACT_APP_BACKEND_URL);
const adminId = process.env.REACT_APP_ADMIN_ID;

const useStyles = makeStyles((theme) => ({
  mainBody: {
    height: "100Vh",
    display: "flex",
    flexDirection: "column",
  },
  mainContainer: {
    width: "100%",
    height: "80%",
    margin: "auto",
    display: "grid",
    gridTemplateRows: "1fr 3fr",
    gridTemplateColumns: "1fr",
    gridTemplateAreas: `
        'topPart '
        'bottomPart'
      `,
    padding: "2rem 2rem 2rem 2rem ",
    gap: 10,
    boxSizing: "border-box",
    columnGap: "2rem",
  },
  topPart: {
    boxSizing: "border-box",
    gridArea: "topPart",
    width: "100%",
    height: "100%",
    display: "flex",
    gap: 15,
  },
  logoImg: {
    width: "80%",
    height: "80%",
    objectFit: "contain",
  },
  spotRate: {
    width: "40%",
    backgroundColor: "#C19A39",
    border: "1px solid #948B5C",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    color: "#16341B",
    gap: ".6rem",
    borderRadius: "10px",
    color: "#FFFFFF",
  },
  spotRateBoxGoldRowCol: {
    display: "flex",
    flexDirection: "column",
    gap: "0.3rem",
    alignItems: "center",
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
  },
  spotRateValueGold: {
    border: "1px solid #948B5C",
    color: "#FFFFFF",
    borderRadius: "5px",
    width: "8.3vw",
    height: "7vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "background-color .5s ease",
  },
  spotRateValueSilver: {
    border: "1px solid #948B5C",
    color: "#FFFFFF",
    borderRadius: "5px",
    width: "8.3vw",
    height: "7vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "background-color .5s ease",
  },

  bottomPart: {
    gridArea: "bottomPart",
    width: "100%",
    height: "100%",
    display: "flex",
    gap: 20,
    // boxSizing: "border-box",
  },
  commoditieTable: {
    height: "100%",
    width: "70%",
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    color: "#000000",
  },
  table: {
    gridArea: "table",
    gap: "15px",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  tabeHeader: {
    backgroundColor: "#FFFFFF",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "15%",
    color: "#330411",
  },
  tableContent: {
    gridArea: "tableContent",
    display: "flex",
    height: "83%",
    gap: 10,
    flexDirection: "column",
    color: "#FFFFFF",
    overflowX: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tableRowColumn: {
    flex: "1",
    display: "flex",
    alignItems: "center",
    fontSize: "2vw",
  },
  tableRow1: {
    flexBasis: 0,
    minHeight: "18%",
    maxHeight: "18%",
    flexGrow: 1,
    gridArea: "tableRow1",
    backgroundColor: "#DEBE6D",
    display: "flex",
    clipPath: " polygon(0 0, 100% 0, 97% 100%, 0 100%)",
  },
  bannerAndCurrency: {
    height: "100%",
    width: "30%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    alignItems: "end",
  },
  carousel: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "20px",
  },
  bannerImg: {
    width: "100%",
    height: "42vh",
    objectFit: "cover",
    borderRadius: "10px",
  },
  ratioAndNews: {
    padding: "0rem 2rem .5rem 2rem ",
    height: "20%",
    display: "flex",
    flexDirection: "column",
  },
  IconAndRatio: {
    height: "60%",
    width: "100%",
    display: "flex",
  },
  ratio: {
    height: "100%",
    width: "50%",
    gridArea: "stat",
    backgroundColor: "#002223",
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    paddingRight: "75px",
    clipPath: "polygon(0 0, 90% 0%, 100% 100%, 0% 100%)",
  },
  bullionStatsImg: {
    width: "18vw",
    marginLeft: "8em",
    height: "auto",
  },
  updates: {
    color: "#fff",
    display: "flex",
    alignItems: "start",
    justifyContent: "center",
    color: "#FFFFFF",
    height: "40%",
  },
  updatesHeader: {
    background: "#002223",
    width: "15%",
    height: "82%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  updatesContent: {
    display: "flex",
    alignItems: "center",
    background: "#C19A39",
    width: "100%",
    height: "80%",
    border: "1px solid #FFFFFF1A",
  },
  /////////////////////////////////////////
  currencyCommoditieTable: {
    height: "100%",
    width: "100%",
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    color: "#000000",
    borderRadius: "5px 5px 0 0",
  },
  currencyTable: {
    gridArea: "table",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    borderRadius: "5px 5px 0 0",
  },
  currencyTabeHeader: {
    backgroundColor: "#C19A39",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "25%",
    color: "white",
    borderRadius: "5px 5px 0 0",
  },
  currencyTableContent: {
    gridArea: "tableContent",
    display: "flex",
    height: "75%",
    width: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
    color: "#FFFFFF",
    gap: 2,
    overflowX: "scroll",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  currencyTableRowColumn: {
    // flex: "1",
    display: "flex",
    alignItems: "center",
    fontSize: "2vw",
    justifyContent: "center",
  },
  currencyTableRow: {
    flexBasis: 0,
    height: "25%",
    flexGrow: 1,
    backgroundColor: "#DEBE6D",
    display: "flex",
    color: "#000000",
  },
  currencyImage: {
    height: "20%",
  },
  /////////////////////////////////////////

  blur: {
    filter: "blur(8px)",
  },
}));

const Home = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { tv } = useSelector((state) => ({ ...state }));
  const classes = useStyles();
  const [news, setNews] = useState([]);
  const [banners, setBanners] = useState([]);
  const [commodities, setCommodities] = useState([]);
  const [isMarketOpen, setIsMarketOpen] = useState(true);
  const [remainingTime, setRemainingTime] = useState(null);
  const [openlimitExpierd, setOpenlimitExpierd] = useState(false);
  const [openSubscription, setOpenSubscription] = useState(false);
  const [openSubscriptionExpierd, setOpenSubscriptionExpierd] = useState(false);
  const [time, setTime] = useState(new Date());
  const [spread, setSpread] = useState({
    goldAskSpread: 0,
    goldBidSpread: 0,
    silverAskSpread: 0,
    silverBidSpread: 0,
    goldLowSpread: 0,
    goldHighSpread: 0,
    silverLowSpread: 0,
    silverHighSpread: 0,
  });

  const [ratio, setRatio] = useState({
    Buyers: "77%",
    chgValue: "+0.370%",
    commodity: "Gold",
  });
  const [displayBidOrBuy, setDisplayBidOrBuy] = useState({
    bidOrBuy: "Bid",
    askOrSell: "Ask",
  });

  const [gold, setGold] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [silver, setSilver] = useState({
    cur: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
    pre: { ask: 0, bid: 0, highPrice: 0, lowPrice: 0 },
  });
  const [render, setRender] = useState(true);

  const [deviceName, setDeviceName] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [userIp, setUserIp] = useState("Ip not found");
  const [openBlocked, setOpenBlocked] = useState(false);

  useMemo(() => {
    if (socket && render) {
      setRender(false);
      socket.on("connect", () => {});
      socket.on("disconnect", () => {});
      socket.on("connect_error", (err) => {
        console.log(`connect_error due to ${err.message}`);
      });
      socket.on("gold-rate-change", (data) => {
        setIsMarketOpen(data.data.isMarketOpen);
        setGold((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
      socket.on("silver-rate-change", (data) => {
        setSilver((e) => {
          return { cur: data.data, pre: e.cur };
        });
      });
    }
  }, [socket]);

  useEffect(() => {
    let timerId;
    if (!isMarketOpen) {
      timerId = setInterval(() => {
        let currentTime = new Date();
        let nextMonday = new Date();
        let dubaiTime = moment(Date.now()).tz("Asia/Dubai");
        nextMonday.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setFullYear(
          Number(dubaiTime.format("YYYY")),
          Number(dubaiTime.format("MM")) - 1,
          Number(dubaiTime.format("D"))
        );
        currentTime.setHours(
          dubaiTime.format("H"),
          dubaiTime.format("mm"),
          dubaiTime.format("ss")
        );

        if (dubaiTime.format("ddd") !== "Mon") {
          nextMonday.setDate(
            nextMonday.getDate() + ((1 + 7 - nextMonday.getDay()) % 7 || 7)
          );
        }
        nextMonday.setHours(1, 59, 0, 0);
        const elapsedTime = nextMonday - currentTime;
        if (elapsedTime <= 0) {
          clearInterval(timerId);
        } else {
          setRemainingTime(elapsedTime);
        }
      }, 1000);
    }
    return () => clearInterval(timerId); // Clear the timer when the component unmounts or when startTimer becomes false
  }, [isMarketOpen]);

  //////////////////////login//////////////////////////////
  useEffect(() => {
    handleFindUserDatas();
  }, []);

  useEffect(() => {
    if (tv) {
      handleloginChecking();
    }
  }, []);

  useEffect(() => {
    if (tv) {
      const interval = setInterval(() => {
        triggerExpire();
      }, 60 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [tv]);

  useEffect(() => {
    if (tv) {
      handlecheckSubscriptionExpierd();
    }
  }, [tv]);

  const handleOpenBlocked = () => setOpenBlocked(true);
  const handleCloseBlocked = () => {
    window.location.reload();
    // setOpenBlocked(false);
  };

  async function handleFindUserDatas() {
    const deviceip = await findUserIp();
    setUserIp(deviceip.ip);
    firebaseAuth
      .signInAnonymously()
      .then(async (user) => {
        setDeviceId(user?.user?.uid);
      })
      .catch(alert);
    const device = deviceDetect();
    if (device.isMobile) {
      setDeviceName(device.ua);
    } else {
      setDeviceName(device.userAgent);
    }
  }

  useEffect(() => {
    if (deviceName && deviceId && userIp && !tv) {
      handleLogin();
    }
  }, [deviceName, deviceId, userIp]);

  const handleLogin = async () => {
    const res = await tvLoging({
      adminId: adminId,
      deviceName: deviceName,
      password: "123456",
      deviceId: deviceId,
      deviceIp: userIp,
      loginLocation: "Unknown Address",
    });
    if (res.status) {
      dispatch({ type: "LOGIN", payload: res });
      localStorage.setItem("tv", JSON.stringify(res));
      localStorage.setItem("deviceId", JSON.stringify(deviceId));
      handleloginChecking();
    } else {
      if (res == "You have blocked..!") {
        handleOpenBlocked();
        // toast.error(res);s
      } else {
        handleLogout();
      }
    }
  };

  const handleloginChecking = async () => {
    let deviceid = "";
    firebaseAuth
      .signInAnonymously()
      .then(async (user) => {
        deviceid = user?.user?.uid;
        const res = await loginChecking(deviceid);
        if (res.status === false && res?.totalLoginCount <= res?.liveCounts) {
          setOpenlimitExpierd(true);
          // handlecheckSubscriptionExpierd();
        } else if (res.status === false) {
          localStorage.setItem("tv", "");
          localStorage.getItem("deviceId", "");
          Cookies.remove("socketUrl");
          // window.location.reload();
          dispatch({
            type: "LOGOUT",
          });

          handleOpenBlocked();
        }
      })
      .catch(alert);
  };

  const handleLogout = async () => {
    const deviceId = localStorage.getItem("deviceId")
      ? JSON.parse(localStorage.getItem("deviceId"))
      : null;
    const res = await logoutTv({
      adminId: adminId,
      deviceId: deviceId,
    });
    if (res) {
      Cookies.set("tv", "");
      Cookies.set("deviceId", "");
      Cookies.remove("socketUrl");
      dispatch({
        type: "LOGOUT",
      });
      window.location.reload();
    }
  };

  const triggerExpire = () => {
    const givenDate = new Date(tv?.package.expire_date);
    const oneMonthAgo = new Date(givenDate);
    oneMonthAgo.setDate(givenDate.getDate() - 30);
    let a = oneMonthAgo.toISOString();
    let b = new Date().toISOString();

    if (b >= a) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (currentHour === 10) {
        handleOpen();
      }
    }
  };

  const handlecheckSubscriptionExpierd = () => {
    const givenDate = new Date(tv?.package.expire_date);
    let a = givenDate.toISOString();
    let b = new Date().toISOString();
    if (a < b) {
      handleOpenSubscriptionExpierd();
    }
  };

  ////////////////////////////////////////////////////////

  const formatRemainingTime = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const hours = Math.floor((time / 1000 / 60 / 60) % 24);
    const days = Math.floor(time / 1000 / 60 / 60 / 24);

    return `${days}d ${hours}h ${minutes}m ${seconds}s`;
  };

  const commodityCalculation = (
    livePrice,
    spread,
    premium,
    fxRate,
    purity,
    unit,
    weight,
    charge
  ) => {
    let value =
      (Number(livePrice) + Number(spread) + Number(premium)) *
        (fxRate / 31.1035) *
        Number(purity) *
        Number(weight) *
        Number(unit) +
      Number(charge);
    if (value.toFixed(0).length < 5) {
      return value.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return value.toLocaleString("en-US", {
        // minimumFractionDigits: 2,
        maximumFractionDigits: 0,
      });
    }
  };

  useEffect(() => {
    handleFindRatio();
  }, []);

  useEffect(() => {
    hadlefetchNews();
  }, []);

  useEffect(() => {
    handleFindSpread();
  }, []);

  useEffect(() => {
    handleFinsCommodities();
  }, []);

  useEffect(() => {
    handleFindLiveValueTypeForDisplay();
  }, []);

  useEffect(() => {
    handelFindBanners();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    socket2.on("connect", () => {});
    socket2.on("disconnect", () => {});
    socket2.emit("join_room", { roomId: adminId });
    socket2.on("commodityRerender", (data) => {
      handleFinsCommodities();
    });
    socket2.on("newsRerender", (data) => {
      hadlefetchNews();
    });
    socket2.on("spreadRerender", (data) => {
      handleFindSpread();
    });
    socket2.on("loginChecking", (data) => {
      handleloginChecking();
    });
    socket2.on("adminBlockRerender", (data) => {
      handleLogout();
    });
    socket2.on("socketRerender", (data) => {
      Cookies.remove("socketUrl");
      window.location.reload();
    });
  }, []);

  useEffect(() => {
    handleFindSocketUrl();
  }, []);

  const handleFindSocketUrl = async () => {
    const res = await getSocketUrl();
    if (res) {
      socket = io(res?.socketURL);
      Cookies.set("socketUrl", JSON.stringify(res?.socketURL));
    }
  };

  const progress = Number(ratio?.Buyers?.match(/\d+/)[0]);

  const buyersColor = "#2FB545";
  const sellersColor = "#D1172D";

  const progressStyle = {
    backgroundColor: sellersColor,
  };

  const barStyle = {
    backgroundColor: buyersColor,
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const formatDate = () => {
    return moment(new Date()).format("DD MMM YYYY");
  };

  const formatday = () => {
    return moment(new Date()).format("dddd");
  };

  const handleOpen = () => setOpenSubscription(true);
  const handleClose = () => setOpenSubscription(false);
  const handleOpenSubscriptionExpierd = () => setOpenSubscriptionExpierd(true);
  const handleCloseSubscriptionExpierd = () =>
    setOpenSubscriptionExpierd(false);

  const handleFinsCommodities = async () => {
    const res = await allCommodities();
    if (Array.isArray(res.commodities)) {
      setCommodities(res.commodities);
    }
  };

  const handleFindSpread = async () => {
    const res = await getSpread();
    if (res) {
      setSpread(res);
    }
  };

  const handleFindRatio = async () => {
    const res = await getRatio();
    if (res?.data) {
      setRatio(res.data);
    }
  };

  const handelFindBanners = async () => {
    const res = await findBanners();
    if (Array.isArray(res)) {
      setBanners(res);
    }
  };

  const handleFindLiveValueTypeForDisplay = async () => {
    const res = await getLiveValueTypeForDisplay();
    if (res) {
      setDisplayBidOrBuy(res);
    }
  };

  const hadlefetchNews = async () => {
    const res = await allNewses();
    if (Array.isArray(res)) {
      setNews(res);
    }
  };

  return (
    <div
      style={{
        // backgroundImage: `url(${bgimg})`,
        width: "100dvw",
        height: "100dvh",
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        // backgroundPosition: "center",
      }}
      className={(openlimitExpierd || openSubscriptionExpierd) && classes.blur}
    >
      <Box className={classes.mainBody}>
        <Box className={classes.mainContainer}>
          {!isMarketOpen && (
            <Box
              sx={{
                position: "fixed",
                top: "0",
                left: "-125px",
                right: "0",
                bottom: "0",
                width: "895px",
                height: "531px",
                overflow: "hidden",
                zIndex: "99",
              }}
            >
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  height: "10%",
                  transform: "rotate(-35deg)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#C19A39",
                  color: "#FFFFFF",
                  fontSize: "2vw",
                  fontWeight: "bold",
                  padding: "20px",
                  lineHeight: 1.5,
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                }}
              >
                <Marquee delay="2" gradient={false}>
                  {remainingTime && (
                    <p style={{ marginLeft: "90px" }}>
                      Market closed! Opens on{" "}
                      {formatRemainingTime(remainingTime)}
                    </p>
                  )}
                </Marquee>
              </div>
            </Box>
          )}
          <Box className={classes.topPart}>
            <Box sx={{ height: "100%", width: "30%" }}>
              <img className={classes.logoImg} src={logo} alt="tvLogo" />
            </Box>

            <Box className={classes.spotRate}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "20%",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.3vw",
                    fontWeight: "bold",
                    flexBasis: 0,
                    flexGrow: 0.8,
                    flexShrink: 1,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  SPOT RATE
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                >
                  <Typography
                    sx={{
                      backgroundColor: "#002223",
                      borderRadius: "3px",
                      px: "6px",
                    }}
                  >
                    $
                  </Typography>
                  <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                    {displayBidOrBuy?.bidOrBuy?.toUpperCase()}
                  </Typography>
                  <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    flexBasis: 0,
                    flexGrow: 1,
                    flexShrink: 1,
                  }}
                >
                  <Typography
                    sx={{
                      backgroundColor: "#002223",
                      borderRadius: "3px",
                      px: "6px",
                    }}
                  >
                    $
                  </Typography>
                  <Typography sx={{ fontSize: "1.4vw", fontWeight: "bold" }}>
                    &nbsp;
                    {displayBidOrBuy?.askOrSell?.toUpperCase()}
                  </Typography>
                  <Typography style={{ fontSize: "1.2vw" }}>oz</Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  height: "35%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "1.8vw",
                    fontWeight: "bold",
                    p: 0,
                    flexBasis: 0,
                    flexGrow: 0.8,
                    flexShrink: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  GOLD
                  <br />
                  <Typography
                    sx={{
                      fontSize: "1.8vw",
                      fontWeight: "bold",
                      flexBasis: 0,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: -1.5,
                    }}
                  >
                    &nbsp;
                  </Typography>
                </Typography>

                <Box className={classes.spotRateBoxGoldRowCol}>
                  <Box
                    className={classes.spotRateValueGold}
                    sx={{
                      backgroundColor:
                        Number(gold?.cur?.bid).toFixed(2) <
                        Number(gold?.pre?.bid).toFixed(2)
                          ? "#F63544"
                          : Number(gold?.cur?.bid).toFixed(2) >
                            Number(gold?.pre?.bid).toFixed(2)
                          ? "#3DA1EA"
                          : "#000000",
                      px: 1.5,
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                      {(
                        Number(spread?.goldBidSpread) + Number(gold?.cur?.bid)
                      ).toFixed(2)}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: ".9vw",
                        background: "#CE635B",
                        width: "3rem",
                        textAlign: "center",
                      }}
                    >
                      Low
                    </Typography>
                    <Typography style={{ fontSize: "1vw" }}>
                      {(
                        Number(spread.goldLowSpread) +
                        Number(gold?.cur?.lowPrice)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.spotRateBoxGoldRowCol}>
                  <Box
                    className={classes.spotRateValueGold}
                    sx={{
                      backgroundColor:
                        Number(gold?.cur?.ask).toFixed(2) <
                        Number(gold?.pre?.ask).toFixed(2)
                          ? "#F63544"
                          : Number(gold?.cur?.ask).toFixed(2) >
                            Number(gold?.pre?.ask).toFixed(2)
                          ? "#3DA1EA"
                          : "#000000",
                      px: 1.5,
                    }}
                  >
                    <Typography sx={{ fontSize: "2vw", fontWeight: "bold" }}>
                      {(
                        Number(spread?.goldAskSpread) + Number(gold?.cur?.ask)
                      ).toFixed(2)}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: ".9vw",
                        background: "#3DA1EA",
                        width: "3rem",
                        textAlign: "center",
                      }}
                    >
                      High
                    </Typography>
                    <Typography style={{ fontSize: "1vw" }}>
                      {(
                        Number(spread?.goldHighSpread) +
                        Number(gold?.cur?.highPrice)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                background: "#C19A39",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                borderRadius: "20px",
                width: "30%",
                color: "white",
                paddingY: 2,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "2.5vw",
                  display: "flex",
                  width: "50%",
                  justifyContent: "space-between",
                  mb: -1,
                }}
              >
                {formatTime(time)
                  .split("")
                  .map((item, index) => (
                    <span key={index}>{item.toUpperCase()}</span>
                  ))}
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.9vw",
                  letterSpacing: "2.2px",
                  display: "flex",
                  width: "50%",
                  justifyContent: "space-between",
                  mb: -1,
                }}
              >
                {formatday()
                  .split("")
                  .map((item, index) => (
                    <span key={index}>{item.toUpperCase()}</span>
                  ))}
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.8vw",
                  display: "flex",
                  width: "50%",
                  justifyContent: "space-between",
                }}
              >
                {formatDate()
                  .split("")
                  .map((item, index) => (
                    <span key={index}>{item.toUpperCase()}</span>
                  ))}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.bottomPart}>
            <Box className={classes.commoditieTable}>
              <Box className={classes.table}>
                <Box className={classes.tabeHeader}>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    COMMODITY
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    WEIGHT
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    BUY
                    <Typography sx={{ fontSize: "0.9vw", mt: 2 }}>
                      &nbsp;AED
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "2vw",
                      fontWeight: "bold",
                    }}
                  >
                    SELL
                    <Typography sx={{ fontSize: "0.9vw", mt: 2 }}>
                      &nbsp;AED
                    </Typography>
                  </Typography>
                </Box>

                <Box className={classes.tableContent}>
                  {commodities?.map((commodity, idx) => {
                    const words = commodity.commodity_title.split(" ");
                    return (
                      <Box
                        key={idx}
                        className={classes.tableRow1}
                        sx={{
                          maxHeight: commodities.length > 6 ? "25%" : "",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "1.3vw",
                            fontWeight: "bold",
                            justifyContent: "left",
                            background: "#C19A39",
                            paddingLeft: { lg: ".5vw" },
                            clipPath: "polygon(0% 0, 100% 0, 98% 100%, 0 100%)",
                          }}
                          className={classes.tableRowColumn}
                        >
                          {words?.map((word, index) => {
                            if (index === 0) {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "2vw",
                                    fontWeight: "bold",
                                    paddingLeft: "30px",
                                  }}
                                >
                                  {word.toUpperCase()}
                                </span>
                              );
                            } else {
                              return (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: "1vw",
                                    marginTop: "0.2rem",
                                    fontWeight: "bold",
                                  }}
                                >
                                  &nbsp;{word.toUpperCase()}
                                </span>
                              );
                            }
                          })}
                          &nbsp;
                          {commodity.unitLabel === "TTB"
                            ? ""
                            : commodity.displayPurity}
                        </Typography>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                            background: "#CAA64C",
                            marginLeft: "-8px",
                            clipPath:
                              " polygon(2% 0, 100% 0, 98% 100%, 0 100%)",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "2vw",
                              fontWeight: "bold",
                            }}
                            sx={{
                              paddingLeft: { lg: "5.2vw", md: "5.2vw" },
                            }}
                          >
                            {commodity.unit} {commodity.unitLabel}
                          </Typography>
                        </Box>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                            background: "#D2B15D",
                            marginLeft: "-8px",
                            clipPath:
                              " polygon(2% 0, 100% 0, 98% 100%, 0 100%)",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "2vw",
                              fontWeight: "bold",
                            }}
                            sx={{
                              paddingLeft: { lg: "5.2vw", md: "5.2vw" },
                            }}
                          >
                            {commodityCalculation(
                              commodity.commodity_title === "Silver"
                                ? silver?.cur?.bid
                                : gold?.cur?.bid,
                              commodity.commodity_title === "Silver"
                                ? spread?.silverBidSpread
                                : spread?.goldBidSpread,
                              commodity?.buy_premium,
                              3.674,
                              commodity.purity,
                              commodity.unit,
                              commodity.unitLabel === "TTB"
                                ? 116.64
                                : commodity.unitLabel === "KG"
                                ? 1000
                                : commodity.unitLabel === "OZ"
                                ? 31.1
                                : commodity.unitLabel === "TOLA"
                                ? 11.7
                                : 1,
                              commodity.buy_charge
                            )}
                          </Typography>
                        </Box>
                        <Box
                          className={classes.tableRowColumn}
                          sx={{
                            justifyContent: "left",
                            // marginLeft: "-8px",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "2vw", fontWeight: "bold" }}
                            sx={{
                              paddingLeft: { lg: "5vw", md: "5vw" },
                            }}
                          >
                            {commodityCalculation(
                              commodity.commodity_title === "Silver"
                                ? silver?.cur?.ask
                                : gold?.cur?.ask,
                              commodity.commodity_title === "Silver"
                                ? spread.silverAskSpread
                                : spread.goldAskSpread,
                              commodity.premium,
                              3.674,
                              commodity.purity,
                              commodity.unit,
                              commodity.unitLabel === "TTB"
                                ? 116.64
                                : commodity.unitLabel === "KG"
                                ? 1000
                                : commodity.unitLabel === "OZ"
                                ? 31.1
                                : commodity.unitLabel === "TOLA"
                                ? 11.7
                                : 1,
                              commodity.charges
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>
            <Box className={classes.bannerAndCurrency}>
              <Box sx={{ height: "83%", width: "100%" }}>
                <Box className={classes.carousel}>
                  <Carousel
                    animation="fade" // Set the animation type to slide
                    navButtonsAlwaysVisible={false} // Show navigation buttons always
                    interval={10000}
                    indicatorContainerProps={{
                      style: {
                        display: "none",
                      },
                    }}
                    sx={{
                      height: "100%",
                      width: "100%",
                    }}
                    transitionTime={3000} // Adjust the transition time to control the speed of the fade effect
                    autoPlay
                    duration={2000}
                  >
                    {banners?.map((banner, i) => (
                      <img
                        className={classes.bannerImg}
                        src={banner.imageUrl}
                        alt="banner"
                      />
                    ))}
                  </Carousel>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={classes.ratioAndNews}>
          <Box className={classes.IconAndRatio}>
            <Box
              sx={{
                height: "100%",
                width: "13%",
                background: "#132D32",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{ objectFit: "contain", width: "90px", height: "90px" }}
                src={sajLogo}
                alt="logo"
              />
            </Box>
            <Box className={classes.ratio}>
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 5,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: "1vw" }}>BUYERS</Typography>
                  <Typography
                    sx={{
                      color: "#F8FBFF",
                      fontSize: "1vw",
                      color:
                        Number(ratio?.chgValue.replace("%", "")) >= 0
                          ? "#2FB545"
                          : "#DE5B56",
                    }}
                  >
                    {ratio?.chgValue}
                  </Typography>
                  <Typography sx={{ fontSize: "1vw" }}>SELLERS</Typography>
                </Box>
                <Box sx={{ width: "90%", px: 4 }}>
                  <LinearProgress
                    variant="determinate"
                    value={progress}
                    style={progressStyle}
                    sx={{
                      "& .MuiLinearProgress-bar": barStyle,
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    px: 7,
                    py: 1,
                    boxSizing: "border-box",
                  }}
                >
                  <Typography sx={{ fontSize: "1vw", color: "#2FB545" }}>
                    {ratio?.Buyers}
                  </Typography>
                  {/* <Box sx={{ mt: 1 }}>
                    <a href="https://www.bullionstats.com/">
                      <img
                        className={classes.bullionStatsImg}
                        src={bullionStats}
                        alt="bullionStats"
                      />
                    </a>
                  </Box> */}

                  <Typography sx={{ fontSize: "1vw", color: "#DE5B56" }}>
                    {100 - Number(ratio?.Buyers?.match(/\d+/)[0])}%
                  </Typography>
                </Box>
              </>
            </Box>
            <Box
              sx={{
                width: "37%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a href="https://www.bullionstats.com/">
                <img
                  className={classes.bullionStatsImg}
                  src={bullionStats}
                  alt="bullionStats"
                />
              </a>
            </Box>
          </Box>
          <Box className={classes.updates}>
            <Box className={classes.updatesHeader}>
              <Typography
                sx={{
                  fontSize: "1.7vw",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                SAJ NEWS
              </Typography>
            </Box>
            <Box className={classes.updatesContent}>
              <marquee behavior="" direction="">
                <Box sx={{ display: "flex" }}>
                  {news?.map((item, index) => {
                    return (
                      <Box
                        key={item._id}
                        style={{
                          fontSize: "1.5vw",
                          fontFamily: "poppins",
                          alignItems: "center",
                          display: "flex",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {item.newsTitle}
                        </span>
                        &nbsp;&nbsp;:&nbsp;&nbsp;<span>{item.newsDetails}</span>
                        <span
                          style={{ paddingRight: "50px", paddingLeft: "50px" }}
                        >
                          {index === news.length - 1 ? "" : "|"}
                        </span>
                      </Box>
                    );
                  })}
                </Box>
              </marquee>
              <LogoutIcon
                sx={{ fontSize: "30px", color: "white", cursor: "pointer" }}
                onClick={handleLogout}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openlimitExpierd}
        closeAfterTransition
      >
        <Box>
          <LimitExceededModal logout={handleLogout} />
        </Box>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscription}
        onClose={handleClose}
        closeAfterTransition
      >
        <Box>
          <SubscriptionExpiringSoon
            date={tv?.package.expire_date}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openSubscriptionExpierd}
        onClose={handleCloseSubscriptionExpierd}
        closeAfterTransition
      >
        <Box>
          <SubscriptionExpired handleClose={handleLogout} />
        </Box>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openBlocked}
        onClose={handleCloseBlocked}
        closeAfterTransition
      >
        <Box>
          <BlockModal handleClose={handleCloseBlocked} />
        </Box>
      </Modal>
    </div>
  );
};

export default Home;
